<router-outlet></router-outlet>
<ng-container *ngIf="store.carType && showContactForm">
  <app-card class="expandable-panel" *transloco="let t">
    <header>
      <h3 (click)="onToggleExpansionPanel($event)">
        {{ t('contactForm.title') }}
        <span class="material-icons"><img src="/assets/images/plus.svg"/></span>
      </h3>
    </header>
    <section>
      <app-no-encapsulation>
        <app-contact-form [calcData]="store"></app-contact-form>
      </app-no-encapsulation>
    </section>
  </app-card>
</ng-container>
  <app-card class="expandable-panel" *transloco="let t">
    <header>
      <h3 (click)="onToggleExpansionPanel($event)">
        {{ t('sources.title') }}
        <span class="material-icons"><img src="/assets/images/plus.svg"/></span>
      </h3>
    </header>
    <section>
      <app-no-encapsulation>
        <div class="sources" [innerHTML]="'sources.description' | transloco | safe: 'html'"></div>
      </app-no-encapsulation>
    </section>
  </app-card>
     <app-card class="expandable-panel" *transloco="let t">
    <header>
      <h3 (click)="onToggleExpansionPanel($event)">
        {{ t('disclaimer.title') }}
        <span class="material-icons"><img src="/assets/images/plus.svg"/></span>
      </h3>
    </header>
    <section>
      <app-no-encapsulation>
        <div class="disclaimer" [innerHTML]="'disclaimer.description' | transloco | safe: 'html'"></div>
      </app-no-encapsulation>
    </section>
  </app-card> 
