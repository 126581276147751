<ng-container *transloco="let t; read: 'contactForm'">
    <app-header header="contactForm.header"></app-header>
    <form [formGroup]="form">
        <div class="form-group">
            <mat-form-field appearance="none">
                <input matInput type="text" formControlName="companyName" placeholder="{{ t('companyName') }}"
                [ngClass]="{'input-error': form.controls.companyName.invalid && submitted}">
            </mat-form-field>

            <mat-form-field appearance="none">
                <input matInput type="text" formControlName="contactPerson" placeholder="{{ t('contactPerson') }}"
                       [ngClass]="{'input-error': form.controls.contactPerson.invalid && submitted}">
            </mat-form-field>

            <mat-form-field appearance="none">
                <mat-select #contact formControlName="contact" (ngModelChange)="selectContactOption($event)"
                            [ngClass]="{'input-error': form.controls.contact.invalid && submitted}">
                    <mat-option value="" disabled selected>{{ t('contact') }}</mat-option>
                    <mat-option value="email">{{ t('email') }}</mat-option>
                    <mat-option value="telefon">{{ t('telefon') }}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="none" *ngIf="contactType==='email'">
                <input matInput type="email" formControlName="email" placeholder="{{ t('email') }}" email
                       [ngClass]="{'input-error': form.controls.email.invalid && submitted}">
            </mat-form-field>

            <mat-form-field appearance="none" *ngIf="contactType==='telefon'">
                <input matInput type="tel" formControlName="telefon" placeholder="{{ t('telefon') }}"
                       [ngClass]="{'input-error': form.controls.telefon.invalid && submitted}">
            </mat-form-field>

            <div class="form-control form-control-text-area">
                <mat-form-field appearance="none">
                    <textarea class="content" matInput placeholder="{{ t('content') }}" formControlName="content"></textarea>
                </mat-form-field>
            </div>

            <p class="note">
                {{ t('note') }}
            </p>

            <div id="parent">
                <input class="agree" formControlName="termsAccepted"  type="checkbox" id="checkbox"
                       [ngClass]="{'input-error': form.controls.termsAccepted.invalid && submitted}"/>
                <p class="agree note" [innerHTML]="t('agree') | transloco | safe: 'html'"></p>
            </div>

            <div class="buttons">
                <button type="submit" [disabled]="form.invalid">
                    <app-button (click)="sendEmail()" theme="secondary" >
                        {{ t('sendButton') }}
                    </app-button>
                </button>
            </div>
        </div>
    </form>
</ng-container>
