import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, take, tap } from 'rxjs/operators';
import { cantons } from 'src/app/data/cantons';

import {
  CarType,
  ChargingPlace,
  CalculatorStore,
  CalculationFormData,
  SummaryData,
  LangData,
  Canton,
} from 'src/app/interfaces';

import { Store } from './store';

type FuelResponse = {
  success: boolean;
  results: {
    country: string;
    gasoline: string;
    currency: string;
    diesel: string;
    lpg: string;
  }[];
};

type ChfRate = {
  rates: {
    CHF: {
      currency_name: string;
      rate: string;
      rate_for_amount: string;
    };
  };
  status: 'success';
};

const initialValue: CalculatorStore = {
  carType: null,
  annualMileage: null,
  chargingPlaces: [],
  form: {
    timeOfUse: null,
    annualMileage: null,
    fuelCosts: null,
    canton: undefined,
    conventional: {
      carPrice: null,
      consumption: null,
      emission: null,
      fuel: null,
      insurance: null,
      maintenanceCosts: null,
    },
    electric: {
      carPrice: null,
      consumption: null,
      electricityCostsAtChargingStationBusiness: null,
      electricityCostsAtHomeBusiness: null,
      electricityCostsAtWorkBusiness: null,
      electricityCostsAtChargingStation: null,
      electricityCostsAtHome: null,
      electricityCostsAtWork: null,
      electricityTypeFromHome: null,
      electricityTypeFromRoute: null,
      electricityTypeFromWork: null,
      emission: null,
      insurance: null,
      maintenanceCosts: null,
      percentageChargingAtHome: null,
      percentageChargingOnTheRoute: null,
      percentageChargingOnWork: null,
    },
  },
  summary: {
    totalCosts: { per1Year: null, perXYears: null, per100km: null },
    fuelCosts: { per1Year: null, perXYears: null, per100km: null },
    co2UsageEmission: { per1Year: null, perXYears: null, per100km: null },
    co2FullLifeCycleEmission: {
      per1Year: null,
      perXYears: null,
      per100km: null,
    },
    otherCosts: { per1Year: null, perXYears: null, per100km: null },
    emission: { per1Year: null, perXYears: null, per100km: null },
    breakEvenPoint: null,
    timeOfUse: null,
    chartData: [[], [], []],
    emissionChartData: [[], [], []],
    emissionChartDataSummary: [],
  },
  lang: {
    lang: null,
    currency: null,
  },
};

@Injectable({ providedIn: 'root' })
export class CalculatorStoreService extends Store<CalculatorStore> {
  carType$ = this.select((state) => state.carType);
  canton$ = this.select((state) => state.form.canton);
  annualMileage$ = this.select((state) => state.annualMileage);
  chargingPlaces$ = this.select((state) => state.chargingPlaces);
  calculatorFormData$ = this.select((state) => state.form);
  summaryData$ = this.select((state) => state.summary);
  lang$ = this.select((state) => state.lang);
  percentageChargingAtHome$ = this.select(
    (state) => state.form?.electric?.percentageChargingAtHome
  );
  percentageChargingAtWork$ = this.select(
    (state) => state.form?.electric?.percentageChargingOnWork
  );
  percentageChargingOnTheRoute$ = this.select(
    (state) => state.form?.electric?.percentageChargingOnTheRoute
  );
  chfRate: number;

  constructor(private httpClient: HttpClient) {
    super({ ...initialValue });
  }

  updateLang(langData: LangData) {
    this.dispatch((state) => ({
      ...state,
      lang: {
        ...state.lang,
        ...langData,
      },
    }));
  }

  updateCarType(carType: CarType) {
    this.dispatch((state) => ({ ...state, carType }));
  }

  updateAnnualMileage(annualMileage: number) {
    this.dispatch((state) => ({ ...state, annualMileage }));
  }

  updateChargingPlace(chargingPlace: ChargingPlace[]) {
    this.dispatch((state) => ({
      ...state,
      chargingPlace,
    }));
  }

  getFuelCosts() {
    if (
      localStorage.getItem('expirationDate') &&
      localStorage.getItem('diesel') &&
      localStorage.getItem('petrol') &&
      localStorage.getItem('chfRate')
    ) {
      const now = new Date();
      const expirationDate = new Date(localStorage.getItem('expirationDate'));
      const expiresIn = expirationDate.getTime() - now.getTime();
      if (expiresIn > 0) {
        return this.dispatch((state) => ({
          ...state,
          form: {
            ...state.form,
            fuelCosts: {
              diesel: +localStorage.getItem('diesel'),
              petrol: +localStorage.getItem('petrol'),
            },
          },
        }));
      } else {
        this.updateFuelCosts();
      }
    } else {
      this.updateFuelCosts();
    }
  }

  updateCalculatorFormData(formData: Partial<CalculationFormData>) {
    this.dispatch((state) => ({
      ...state,
      form: {
        ...state.form,
        timeOfUse: !!formData.timeOfUse
          ? formData.timeOfUse
          : state.form.timeOfUse,
        annualMileage: !!formData.annualMileage
          ? formData.annualMileage
          : state.form.annualMileage,
        fuelCosts: !!formData.fuelCosts
          ? formData.fuelCosts
          : state.form.fuelCosts,
        conventional: { ...state.form.conventional, ...formData.conventional },
        electric: { ...state.form.electric, ...formData.electric },
        canton: !!formData.canton ? formData.canton : state.form.canton,
      },
    }));
  }

  updateSummary(summary: SummaryData) {
    this.dispatch((state) => ({
      ...state,
      summary: {
        ...state.summary,
        ...summary,
      },
    }));
  }

  updateCanton(canton: Canton) {
    this.dispatch((state) => ({
      ...state,
      form: { ...state.form, canton: canton },
    }));
  }

  private updateFuelCosts() {
    this.httpClient
      .get(
        'https://currency-converter5.p.rapidapi.com/currency/convert?format=json&from=EUR&to=CHF&amount=1',
        {
          headers: {
            'X-RapidAPI-Key':
              '912d3d65a2mshaeba9cfa6c7f8f8p1c0149jsn20b2a201aa6b',
            'X-RapidAPI-Host': 'currency-converter5.p.rapidapi.com',
          },
        }
      )
      .subscribe((data: ChfRate) => {
        if (data.status !== 'success') {
          throw Error('Fetching CHF rate failed!');
        }

        localStorage.setItem('chfRate', data.rates.CHF.rate);
      });

    this.httpClient
      .get('https://api.collectapi.com/gasPrice/europeanCountries', {
        headers: {
          authorization: 'apikey 1Mq0T9i7IHL9vEDGSlKDa5:22mCEbAZ4CLnW84vhuEBWK',
          'content-type': 'application/json',
        },
      })
      .subscribe((data: FuelResponse) => {
        if (!data.success) {
          throw Error('updateFuelCosts() failed - FuelResponse error');
        }

        const swissData = data.results.find(
          (country) => country.country === 'Switzerland'
        );

        if (!swissData) {
          throw Error('updateFuelCosts() failed - no fuel data');
        }

        const chfRate = +localStorage.getItem('chfRate');
        const dieselCost = +swissData.diesel.replace(',', '.') * chfRate;
        const petrolCost = +swissData.gasoline.replace(',', '.') * chfRate;

        if (dieselCost && petrolCost) {
          localStorage.setItem('diesel', dieselCost.toFixed(2));
          localStorage.setItem('petrol', petrolCost.toFixed(2));
        }

        /*Store value once per day*/
        const expirationDate = new Date(new Date().getTime() + 86400 * 1000);
        localStorage.setItem('expirationDate', expirationDate.toISOString());
        return this.dispatch((state) => ({
          ...state,
          form: {
            ...state.form,
            fuelCosts: {
              diesel: dieselCost,
              petrol: petrolCost,
            },
          },
        }));
      });
  }
}
