import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

import { Language, ValueText } from 'src/app/interfaces';

@Component({
  selector: 'app-speedometer',
  templateUrl: './speedometer.component.html',
  styleUrls: ['./speedometer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpeedometerComponent {
  get selectedItemIndex(): number {
    return this.items?.findIndex((x) => x.value === this.selectedValue);
  }

  @Input()
  items: ValueText[];

  @Input()
  selectedValue: number;

  @Output()
  selectItem = new EventEmitter<ValueText>();

  private movingEl: HTMLElement;

  @ViewChild('itemsel')
  itemsEl: ElementRef;

  @HostListener('document:mouseup')
  onMouseUp() {
    this.movingEl = null;
  }

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    const { clientX, clientY } = event;
    this.onMove(clientX, clientY);
  }

  standardFormatting: string
  constructor(private translocoService: TranslocoService) {}

  ngOnInit() {
    this.standardFormatting = this.translocoService.getActiveLang() === 'en' ? Language.English : Language.French + '-CH';
  }

  trackByFunction(index: number) {
    return index;
  }

  onSelectItem(item: ValueText) {
    this.selectItem.emit(item);
  }

  onMouseDown(event: MouseEvent) {
    this.movingEl = event.currentTarget as HTMLElement;
  }

  onTouchStart(event: TouchEvent) {
    this.movingEl = event.currentTarget as HTMLElement;
  }

  onTouchMove(event: TouchEvent) {
    const { clientX, clientY } = event.touches[0];
    this.onMove(clientX, clientY);
    event.preventDefault();
  }

  onTouchEnd() {
    this.movingEl = null;
  }

  private onMove(clientX: number, clientY: number) {
    if (this.movingEl) {
      const mouseCordinates = [clientX, clientY];
      const pointerElements = [
        ...this.itemsEl.nativeElement.querySelectorAll('.items__pointer'),
      ];
      const pointerElementsCords = pointerElements.map((x: HTMLElement) => {
        const rect = x.getBoundingClientRect();
        return [
          [rect.left, rect.bottom],
          [rect.right, rect.top],
        ];
      });

      const index = this.which(mouseCordinates, pointerElementsCords);
      if (index !== -1) {
        this.selectItem.emit(this.items[index]);
      }
    }
  }

  private which(click: number[], buttons: number[][][]) {
    let bl = null;
    let button = null;
    let tr = null;

    for (let i = 0, length = buttons.length; i < length; i++) {
      button = buttons[i];
      bl = button[0];
      tr = button[1];

      if (
        click[0] >= bl[0] &&
        click[0] <= tr[0] &&
        click[1] >= bl[1] &&
        click[1] <= tr[1]
      ) {
        return i;
      }
    }

    const distances = Array();
    for (let i = 0, length = buttons.length; i < length; i++) {
      button = buttons[i];
      bl = button[0];
      tr = button[1];

      if (click[0] >= bl[0] && click[0] <= tr[0]) {
        distances[i] = Math.min(
          Math.abs(click[1] - bl[1]),
          Math.abs(click[1] - tr[1])
        );
      } else if (click[1] >= bl[1] && click[1] <= tr[1]) {
        distances[i] = Math.min(
          Math.abs(click[0] - bl[0]),
          Math.abs(click[0] - tr[0])
        );
      } else {
        distances[i] = Math.sqrt(
          Math.pow(
            Math.min(Math.abs(click[0] - bl[0]), Math.abs(click[0] - tr[0])),
            2
          ) +
            Math.pow(
              Math.min(Math.abs(click[1] - bl[1]), Math.abs(click[1] - tr[1])),
              2
            )
        );
      }
    }

    let minIndex = 0;
    for (let j = 0, length = distances.length; j < length; j++) {
      if (distances[j] < distances[minIndex]) {
        minIndex = j;
      }
    }

    return minIndex;
  }
}
