import { Component, OnInit, Input, HostBinding } from '@angular/core';

@Component({
  selector: 'app-select-icon',
  templateUrl: './select-icon.component.html',
  styleUrls: ['./select-icon.component.scss'],
})
export class SelectIconComponent implements OnInit {
  @Input()
  imageSrc: string;

  @Input()
  isSelected: boolean;

  @HostBinding('class') get classes() {
    return {
      'is-selected': this.isSelected,
    };
  }

  constructor() {}

  ngOnInit(): void {}
}
