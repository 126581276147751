import {
  CarType,
  ChargingPlace,
  CarTypeListItem,
  ChargingPlaceListItem,
  CalculationData,
  Canton,
} from '../interfaces';
import { cantons } from './cantons';

export const carTypes: CarTypeListItem[] = [
  {
    imageSrc: 'assets/images/car-types/microcar.svg',
    translationKey: 'type.microcar',
    carType: CarType.MicroCar,
  },
  {
    imageSrc: 'assets/images/car-types/compact.svg',
    translationKey: 'type.compact',
    carType: CarType.Compact,
  },
  {
    imageSrc: 'assets/images/car-types/middle-class.svg',
    translationKey: 'type.middleClass',
    carType: CarType.MiddleClass,
  },
  {
    imageSrc: 'assets/images/car-types/suv.svg',
    translationKey: 'type.suv',
    carType: CarType.Suv,
  },
  {
    imageSrc: 'assets/images/car-types/sports-car.svg',
    translationKey: 'type.sportsCar',
    carType: CarType.SportsCar,
  },
];

export const chargingPlaces: ChargingPlaceListItem[] = [
  {
    imageSrc: 'assets/images/charging-place/home.svg',
    imageSrcChart: 'assets/images/charging-place/home-chart.png',
    invertedImageSrc: 'assets/images/charging-place/home-inverted.svg',
    translationPrivateKey: 'chargingPlace.private.home',
    translationBusinessKey: 'chargingPlace.business.home',
    chargingPlace: ChargingPlace.Home,
  },
  {
    imageSrc: 'assets/images/charging-place/road.svg',
    imageSrcChart: 'assets/images/charging-place/road-chart.png',
    invertedImageSrc: 'assets/images/charging-place/road-inverted.svg',
    translationPrivateKey: 'chargingPlace.private.road',
    translationBusinessKey: 'chargingPlace.business.road',
    chargingPlace: ChargingPlace.Road,
  },
  {
    imageSrc: 'assets/images/charging-place/work.svg',
    imageSrcChart: 'assets/images/charging-place/work-chart.png',
    invertedImageSrc: 'assets/images/charging-place/work-inverted.svg',
    translationPrivateKey: 'chargingPlace.private.work',
    translationBusinessKey: 'chargingPlace.business.work',
    chargingPlace: ChargingPlace.Work,
  },
];

export const calculationData: CalculationData = {
  currency: 'CHF', // 'USD', 'EUR',
  language: localStorage.getItem('lang'), // 'de', 'en'
  timeOfUse: [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
  ],
  annualMileage: [
    5000, 10000, 15000, 20000, 25000, 30000, 35000, 40000, 45000, 50000, 55000,
    60000, 65000, 70000, 75000, 80000,
  ],
  speedometerAnnualMileages: {
    cityTrips: 5000,
    shortDistance: 10000,
    standard: 15000,
    commuters: 20000,
    longDistance: 25000,
  },
  governmentalSubventions: [
    {
      from: 0,
      to: 40000,
      subvention: 9000,
    },
    {
      from: 40000,
      to: 65000,
      subvention: 7500,
    },
  ],
  chargingStationGovernmentalSubventions: [
    {
      from: 0,
      to: 900,
      subvention: 0,
    },
    {
      from: 901,
      to: 40000,
      subvention: 900,
    },
  ],
  acquisitionCostOfChargingStation: [
    0, 500, 1000, 1500, 2000, 2500, 3000, 3500, 4000, 4500, 5000,
  ],
  electricInsurance: [
    {
      from: 0,
      to: 10,
      cost: 0,
    },
  ],
  defaults: {
    emission: {
      petrol: 2.88,
      diesel: 3.24,
    },
    /*TODO: Check is it already done task Axa01-03*/
    electricityFromValues: {
      mixed: 0.13945,
      green: 0.01970 ,
      petrol: 2.8475,
      diesel: 3.151,
    },
    data: {
      timeOfUse: 8,
      annualMileage: 15000,
      fuelCosts: {
        petrol: 1.279,
        diesel: 1.086,
      },
      canton: undefined,
      conventional: {
        fuel: 'petrol', // 'petrol', 'diesel',
      },
      electric: {
        electricityCostsAtHomeBusiness: 0.00,
        electricityCostsAtHome: 0.21,
        electricityCostsAtChargingStationBusiness: 0.38,
        electricityCostsAtChargingStation: 0.38,
        electricityCostsAtWorkBusiness: 0.21,
        electricityCostsAtWork: 0.00,
        electricityTypeFromHome: 'mixed', // 'mixed', ''green
        electricityTypeFromRoute: 'mixed', // 'mixed', ''green
        electricityTypeFromWork: 'mixed', // 'mixed', ''green
        acquisitionCostOfChargingStationBusiness: 2000,
        acquisitionCostOfChargingStation: 1500,
        acquisitionCostOfChargingStationStatus: true,
        changeChargingPlacePercentageStatus: false,
      },
    },
    microcar: {
      conventional: {
        diesel: {
          carPrice: null, // in document there is comment 'no cars available'
          consumption: 4.875,
          insurance: 30,
          maintenanceCosts: 204,
          emission: 100,
          productionEmission: 3070.4,
          recycling: 310.9,
        },
        petrol: {
          carPrice: 18128,
          consumption: 4.875,
          insurance: 30,
          maintenanceCosts: 204,
          emission: 100,
          productionEmission: 3070.4,
          recycling: 310.9,
        },
      },
      electric: {
        carPrice: 34790,
        consumption: 13.9,
        insurance: 0,
        maintenanceCosts: 130,
        emission: 0,
        productionEmission: 5696.6,
        recycling: 782.5,
      },
    },
    compact: {
      conventional: {
        diesel: {
          carPrice: 42475,
          consumption: 4.64,
          insurance: 192,
          maintenanceCosts: 350,
          emission: 104,
          productionEmission: 4431.4,
          recycling: 429,
        },
        petrol: {
          carPrice: 40155,
          consumption: 5.64,
          insurance: 192,
          maintenanceCosts: 350,
          emission: 104,
          productionEmission: 4431.4,
          recycling: 429,
        },
      },
      electric: {
        carPrice: 42790,
        consumption: 16.275,
        insurance: 0,
        maintenanceCosts: 230,
        emission: 0,
        productionEmission: 8731.3,
        recycling: 1215.8,
      },
    },
    middleClass: {
      conventional: {
        diesel: {
          carPrice: 55567,
          consumption: 4.56,
          insurance: 228,
          maintenanceCosts: 312,
          emission: 117,
          productionEmission: 4887.8,
          recycling: 515,
        },
        petrol: {
          carPrice: 53997,
          consumption: 6.76,
          insurance: 228,
          maintenanceCosts: 312,
          emission: 117,
          productionEmission: 4887.8,
          recycling: 515,
        },
      },
      electric: {
        carPrice: 45945,
        consumption: 18.77,
        insurance: 0,
        maintenanceCosts: 200,
        emission: 0,
        productionEmission: 8078.4,
        recycling: 1113.3,
      },
    },
    suv: {
      conventional: {
        diesel: {
          carPrice: 55256,
          consumption: 6.275,
          insurance: 252,
          maintenanceCosts: 360,
          emission: 129,
          productionEmission: 5928.5,
          recycling: 601,
        },
        petrol: {
          carPrice: 53135,
          consumption: 7.9,
          insurance: 252,
          maintenanceCosts: 360,
          emission: 129,
          productionEmission: 5928.5,
          recycling: 601,
        },
      },
      electric: {
        carPrice: 53545,
        consumption: 20.525,
        insurance: 0,
        maintenanceCosts: 288,
        emission: 0,
        productionEmission: 10547.6,
        recycling: 1464.7,
      },
    },
    sportsCar: {
      conventional: {
        diesel: {
          carPrice: 113233,
          consumption: 10.13,
          insurance: 252,
          maintenanceCosts: 878,
          emission: 215,
          productionEmission: 5640.1,
          recycling: 571.1,
        },
        petrol: {
          carPrice: 113233,
          consumption: 10.13,
          insurance: 252,
          maintenanceCosts: 878,
          emission: 215,
          productionEmission: 5640.1,
          recycling: 571.1,
        },
      },
      electric: {
        carPrice: 103463,
        consumption: 25.6,
        insurance: 0,
        maintenanceCosts: 570,
        emission: 0,
        productionEmission: 12769.4,
        recycling: 1787.6,
      },
    },
  },
};
