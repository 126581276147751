<app-card class="header-card">
  <div class="logo__container">
    <div class="logo__wrapper">
      <p class="sub-header" *transloco="let t">{{ t(subheader) }}</p>
      <div class="logo__text_wrapper">
        <span class="logo__text" [innerHTML]="header | transloco: opts | safe: 'html'"></span>
        <app-info-button-icon *ngIf="useInHeaderInfoBox" (click)="onShowInfoBox($event)"></app-info-button-icon>
      </div>
    </div>
  </div>
</app-card>
