import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  isCompanyUserListener = new BehaviorSubject<boolean>(Boolean(1));
  isCantontSelectedUserListener = new BehaviorSubject<boolean>(Boolean(0));

  constructor(
  ) { }

  setCompanyTypeUser(isCompany: boolean){
    this.isCompanyUserListener.next(isCompany);
  }

  getIsCompanyTypeUserListener() {
    return this.isCompanyUserListener.asObservable();
  }

  setCantonSelected(selectCanton: boolean){
    this.isCantontSelectedUserListener.next(selectCanton);
  }

  getCantonSelectedListener() {
    return this.isCantontSelectedUserListener.asObservable();
  }

}
