import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { NgxMaskModule, IConfig } from 'ngx-mask';

import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { TranslocoRootModule } from './transloco-root.module';

import { AppComponent } from './app.component';
import { ContactFormModule } from './containers/contact-form.module';
import { FooterComponent } from './components/footer/footer.component';
import { IntroductionComponent } from './containers/introduction/introduction.component';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeFr from '@angular/common/locales/fr';
import localeIt from '@angular/common/locales/it';
import { CalculatorStoreService } from './services/calculator-store.service';
import { calculationData } from './data';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = {};

registerLocaleData(localeDe);
registerLocaleData(localeFr);
registerLocaleData(localeIt);

@NgModule({
  declarations: [AppComponent, FooterComponent, IntroductionComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    TranslocoRootModule,
    BrowserAnimationsModule,
    ContactFormModule,
    NgxMaskModule.forRoot(options),
  ],
  providers: [CalculatorStoreService],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private calculatorStoreService: CalculatorStoreService) {
    this.calculatorStoreService.updateCalculatorFormData(
      calculationData.defaults.data
    );

    this.calculatorStoreService.getFuelCosts();

    this.calculatorStoreService.updateAnnualMileage(
      calculationData.defaults.data.annualMileage
    );

    this.calculatorStoreService.updateLang({
      currency: calculationData.currency,
      lang: calculationData.language,
    });

    // TODO: prevent use this lang
  }
}
