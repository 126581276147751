<ng-container *transloco="let t; read: 'introduction'">
    <app-header header="introduction.header" subheader="introduction.subheader" useInHeader="true"></app-header>
    <app-card>
        <p [innerHTML]="'introduction.content.title' | transloco | safe: 'html'"></p>

        <p [innerHTML]="'introduction.content.paragraph1' | transloco | safe: 'html'"></p>

        <p [innerHTML]="'introduction.content.paragraph2' | transloco | safe: 'html'"></p>

        <div class="buttons">
            <a class="button-wrapper" routerLink="/calculator" (click)="setIsComapnyUser(true)">
                <app-button theme="secondary">{{ t('content.businessContext') }}</app-button>
            </a>

            <a class="button-wrapper" routerLink="/calculator" (click)="setIsComapnyUser(false)">
                <app-button theme="tertiary">{{ t('content.privateContext') }}</app-button>
            </a>
        </div>
    </app-card>
</ng-container>
