import { Component, Input, OnInit } from '@angular/core';
import { HashMap, TranslocoService } from '@ngneat/transloco';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CalculatorStore } from 'src/app/interfaces/index.js';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss'],
})
export class ContactFormComponent implements OnInit {
  lang: string;
  contactType = '';
  submitted = false;

  @Input()
  calcData: CalculatorStore;

  form = new FormGroup(
    {
      companyName: new FormControl('', Validators.required),
      contactPerson: new FormControl('', Validators.required),
      contact: new FormControl('', Validators.required),
      content: new FormControl(''),
      termsAccepted: new FormControl(false, {
        validators: [Validators.required, Validators.requiredTrue],
      }),
    },
    {
      updateOn: 'blur',
    }
  );

  constructor(
    private translocoService: TranslocoService,
    private snackBar: MatSnackBar,
    private httpClient: HttpClient
  ) {}

  ngOnInit(): void {
    this.translocoService.langChanges$.subscribe((lang) => (this.lang = lang));
  }

  sendEmail(): void {
    this.submitted = true;
    if (!this.form.valid) {
      return;
    }

    const t = (key: string, param?: HashMap) =>
      this.translocoService.translate(key, param);
    const nullHandler = (value) => (!value ? '-' : value);

    const subject = t('contactForm.subject', {
      languageCode: this.lang.toUpperCase(),
    });
    const cantonName = this.calcData.form.canton?.name
      ? t('introductionForm.canton.' + this.calcData.form.canton.name)
      : '-';

    const body = `<ul><li>${t(
      'contactForm.companyName'
    )}: ${this.form.value.companyName}</li>
    <li>${t('contactForm.contactPerson')}: ${this.form.value.contactPerson}</li>
    <li>${t('contactForm.contactChannel')}: ${
      this.form.value.contact === 'email'
        ? this.form.value.email
        : this.form.value.telefon
    }</li>
    </ul>

    <p> ${t('contactForm.message')}: ${this.form.value.content || '-'}</p> 

   <ul>
   <li>${t('calculator.timeOfUse')} (${t('years.pluralSentence')}): ${
      this.calcData.form.timeOfUse
    }</li>
    <li>${t('calculator.annualMileage')}: ${this.calcData.annualMileage}</li>
    <li>${t('calculator.carPurchasePrice')} (${t(
      'calculator.conventionalTitle'
    )}): ${nullHandler(this.calcData.form.conventional.carPrice)}</li>
    <li>${t('calculator.carPurchasePrice')} (${t(
      'calculator.electricTitle'
    )}): ${nullHandler(this.calcData.form.electric.carPrice)}</li>
    <li>${t('introductionForm.canton.canton')}: ${cantonName}</li>
    <li>${t('introductionForm.type.carType')}: ${t(
      'introductionForm.type.' + this.calcData.carType
    )}</li></ul>`;

    const formData = new FormData();

    formData.append("apiKey", "5D36B09FD2203AE531023D936EA8A5ACBCBA9A508CAAB62526084E14AB0A1AC3E53FEA34E158ABD3176FBD0BD54943A9");
    formData.append("subject", subject);
    formData.append("from", "upto@e-mobilitaetsrechner.de");
    formData.append("to", "uptosales@axa.ch, leandro.giannetti@axa.ch");
    formData.append("isTransactional", "true");
    formData.append("bodyHtml", body);

    this.httpClient
      .post('https://api.elasticemail.com/v2/email/send', formData)
      .subscribe((resp: { success: boolean }) => {
        if (resp.success) {
          this.snackBar.open(
            this.translocoService.translate('contactForm.snackBar'),
            'X',
            {
              duration: 10000,
            }
          );
          this.submitted = false;
          this.form.reset();
        } else {
          console.error(resp);
        }
      });
  }

  selectContactOption(value: any) {
    this.contactType = value;
    this.form.addControl(value, new FormControl('', Validators.required));
  }
}
