import {Component, ElementRef, Input, OnInit} from '@angular/core';
import {HashMap, TranslocoService} from '@ngneat/transloco';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {InfoBoxDialogComponent} from 'src/app/calculator/calculator-form-shared/components/info-box-dialog/info-box-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {AppService} from 'src/app/services/app.service';

@UntilDestroy()
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input()
  header: string;
  @Input()
  subheader: string;
  @Input()
  opts: HashMap | undefined;
  @Input()
  useInHeader = false;
  @Input()
  useInHeaderInfoBox = false;

  businessUser = false;

  constructor(
    private translocoService: TranslocoService,
    private dialog: MatDialog,
    private appService: AppService
    ) {
    this.appService.getIsCompanyTypeUserListener().subscribe(isCompanyUser => {
      this.businessUser = isCompanyUser;
    });
  }

  ngOnInit(): void {
    this.translocoService.langChanges$.pipe(untilDestroyed(this)).subscribe();
  }

  onShowInfoBox(event?: MouseEvent) {
    const triggerEl = new ElementRef(event.currentTarget);
    this.dialog.open(InfoBoxDialogComponent, {
      maxWidth: '100%',
      width: '550px',
      data: {
        content: this.businessUser ? `emission.business.content` : `emission.private.content`,
        title: this.businessUser ? `emission.business.popUpTitle` : `emission.private.popUpTitle` ,
        triggerEl,
      },
    });
  }
}
