import { Component, OnInit } from '@angular/core';
import {AppService} from 'src/app/services/app.service';

@Component({
  selector: 'app-introduction',
  templateUrl: './introduction.component.html',
  styleUrls: ['./introduction.component.scss'],
})
export class IntroductionComponent implements OnInit {

  constructor(
    private appService: AppService
  ) {}

  ngOnInit(): void {}

  setIsComapnyUser(comapanyUser: boolean) {
    this.appService.setCompanyTypeUser(comapanyUser);
  }
}
