import {DOCUMENT, Location} from '@angular/common';
import {
  Component,
  Renderer2,
  OnInit,
  Inject,
  ChangeDetectorRef, AfterViewChecked,
} from '@angular/core';
import {Router, NavigationStart} from '@angular/router';
import {TranslocoService} from '@ngneat/transloco';
import {filter, pluck} from 'rxjs/operators';

import {CalculatorStore, Language} from './interfaces';
import {CalculatorStoreService} from './services/calculator-store.service';
import {AppService} from 'src/app/services/app.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewChecked {
  private previousUrl: string;
  showContactForm = false;
  private langCodeFromUrl = Object.values(Language).find((lang) =>
    this.location.path().includes('/' + lang)
  );
  store: CalculatorStore;

  constructor(
    @Inject(DOCUMENT)
    private document: Document,
    private location: Location,
    private renderer: Renderer2,
    private router: Router,
    private translocoService: TranslocoService,
    private calculatorStoreService: CalculatorStoreService,
    private changeDetector: ChangeDetectorRef,
    private appService: AppService,
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (this.previousUrl) {
          this.renderer.removeClass(document.body, this.previousUrl);
        }
        const currentUrlSlug = event.url.slice(1).replace('/', '-');
        if (currentUrlSlug) {
          this.renderer.addClass(document.body, currentUrlSlug);
        }

        this.appService.getIsCompanyTypeUserListener().subscribe(isCompanyUser => {
          this.showContactForm = isCompanyUser;
        });

        this.previousUrl = currentUrlSlug;
      }
    });

    this.calculatorStoreService.pipe().subscribe((store) => {
      this.store = store;
    });
  }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }

  ngOnInit() {
    this.trySetDefaultLang();

    const path = this.location.path();
    if (path !== '' && path !== '/') {
      this.router.navigate(['']);
    }

    this.translocoService.events$
      .pipe(
        filter((e) => e.type === 'translationLoadSuccess'),
        pluck('payload')
      )
      .subscribe(({langName, scope}) => {
        /*this.setCookieBar();*/
      });
  }

  onToggleExpansionPanel(event: MouseEvent) {
    const titleEl = event.currentTarget as HTMLElement;
    const groupEl = titleEl.closest('.expandable-panel');

    const hasClass = groupEl.classList.contains('expanded');
    if (hasClass) {
      this.renderer.removeClass(groupEl, 'expanded');
    } else {
      this.renderer.addClass(groupEl, 'expanded');
    }
  }

  private trySetDefaultLang() {
    const lang = this.langCodeFromUrl;

    if (lang) {
      this.translocoService.setActiveLang(lang);
      localStorage.setItem('lang', lang);
    } else {
      this.translocoService.setActiveLang(Language.German);
      localStorage.setItem('lang', Language.German);
    }
  }
}
