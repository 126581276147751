import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'abs',
})
export class AbsPipe implements PipeTransform {
  transform(value: number) {
    return Math.abs(value);
  }
}
