import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ButtonComponent } from './components/button/button.component';
import { InfoButtonIconComponent } from './components/info-button-icon/info-button-icon.component';
import { CardComponent } from './components/card/card.component';
import { SelectIconComponent } from './components/select-icon/select-icon.component';
import { SpeedometerComponent } from './components/speedometer/speedometer.component';
import { NoEncapsulationComponent } from './components/no-encapsulation/no-encapsulation.component';
import { HeaderComponent } from './components/header/header.component';

import { AbsPipe } from './pipes/abs.pipe';
import { AgePipe } from './pipes/age.pipe';
import { SafePipe } from './pipes/safe.pipe';

import { TranslocoRootModule } from '../transloco-root.module';

const declarations = [
  ButtonComponent,
  InfoButtonIconComponent,
  CardComponent,
  SelectIconComponent,
  SpeedometerComponent,
  NoEncapsulationComponent,
  HeaderComponent,
];

const providers = [AbsPipe, AgePipe, SafePipe];

@NgModule({
  declarations: [...declarations, ...providers],
  imports: [CommonModule, TranslocoRootModule],
  providers: [...providers],
  exports: [CommonModule, ...declarations, ...providers, TranslocoRootModule],
})
export class SharedModule {}
