import {
  Component,
  Inject,
  ChangeDetectionStrategy,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-info-box-dialog',
  templateUrl: './info-box-dialog.component.html',
  styleUrls: ['./info-box-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoBoxDialogComponent implements AfterViewInit {
  constructor(
    private readonly matDialogRef: MatDialogRef<InfoBoxDialogComponent>,
    private readonly el: ElementRef,
    @Inject(MAT_DIALOG_DATA)
    public data: { content: string; title: string; triggerEl: ElementRef }
  ) {}

  ngAfterViewInit(): void {
    const rect = this.data.triggerEl.nativeElement.getBoundingClientRect();
    const elRect = this.el.nativeElement.getBoundingClientRect();
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    const freeSpaceW = windowWidth - elRect.width - rect.left;
    let position = null;
    const isFixed =
      window.getComputedStyle(document[0].body).position == 'fixed';

    if (freeSpaceW >= 0) {
      position = {
        left: `${rect.left}px`,
      };
    }

    let top = isFixed ? rect.top : 0;
    const bottom = rect.top + elRect.height + 60;
    if (bottom >= windowHeight) {
      top = windowHeight - elRect.height - 60;
    }

    position = {
      ...position,
      top: `${top}px`,
    };

    setTimeout(() => {
      this.matDialogRef.updatePosition(position);
    });
  }
}
