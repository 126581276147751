import { Component, Input, HostBinding } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input()
  theme = 'primary';

  @HostBinding('class') get classes() {
    return this.theme;
  }

}
